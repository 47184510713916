import Vue from 'vue'
import Router from 'vue-router'
import Layout from '../views/layout/Layout.vue'

Vue.use(Router)

export const constantRouterMap = [
  {
    path: '/',
    name: 'login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/auth',
    name: 'auth',
    component: () => import('../views/Auth.vue')
  },
  {
    path: '/404',
    name: '404',
    component: () => import('../views/miss404.vue')
  },
  {
    path: '/personal',
    component: Layout,
    redirect: '/personal/personal',
    children: [
      {
        meta: { title: '个人中心' },
        path: 'personal',
        component: () => import('@/views/Personal.vue')
      }
    ]
  },
  // 后台首页
  {
    path: '/home',
    component: Layout,
    redirect: '/home',
    children: [
      {
        meta: { title: '首页' },
        path: '/home',
        component: () => import('@/views/layout/home/Home.vue')
      }
    ]
  },
  //系统管理
  {
    path: "/system",
    component: Layout,
    meta: { title: '系统管理' },
    redirect: '/system/index',
    children: [
      {
        meta: { title: '系统数据' },
        path: 'index',
        component: () => import('@/views/layout/Index.vue')
      },
      {
        meta: { title: '字典管理' },
        path: 'dict',
        component: () => import('@/views/layout/system/DictData.vue')
      },
      // {
      //   meta: { title: '消息模板' },
      //   path: 'template',
      //   component: () => import('@/views/layout/system/Template.vue')
      // },
      // {
      //   meta: { title: '角色管理' },
      //   path: 'role',
      //   component: () => import('@/views/layout/system/Role.vue')
      // },
    ]
  },
  //订单数据
  {
    path: '/order',
    component: Layout,
    meta: { title: '运营管理' },
    redirect: '/order/index',
    children: [
      {
        meta: { title: '订单数据' },
        path: 'index',
        component: () => import('@/views/layout/Index.vue')
      },
      {
        meta: { title: '项目管理' },
        path: 'project',
        component: () => import('@/views/layout/order/Project.vue')
      },
    ]
  },
  //销售数据
  {
    path: '/sales',
    component: Layout,
    meta: { title: '销售数据' },
    redirect: '/sales/index',
    children: [
      {
        meta: { title: '销售数据' },
        path: 'index',
        component: () => import('@/views/layout/Index.vue')
      },
      {
        meta: { title: '销售记录管理' },
        path: 'salesRecords',
        component: () => import('@/views/layout/sales/SalesRecords.vue')
      },
      {
        meta: { title: '销售记录管理' },
        path: 'vir',
        component: () => import('@/views/layout/sales/virtualSelectDemo.vue')
      },
    ]
  },
  //财务数据
  {
    path: '/finance',
    component: Layout,
    meta: { title: '财务管理' },
    redirect: '/finance/index',
    children: [
      {
        meta: { title: '财务数据' },
        path: 'index',
        component: () => import('@/views/layout/Index.vue')
      },
    ]
  },
  //基础数据
  {
    path: '/basic',
    component: Layout,
    meta: { title: '基础数据' },
    redirect: '/basic/index',
    children: [
      {
        meta: { title: '基础数据' },
        path: 'index',
        component: () => import('@/views/layout/Index.vue')
      },
      // {
      //   meta: { title: '合同管理' },
      //   path: 'contract',
      //   component: () => import('@/views/layout/basic/Contract.vue')
      // },
    ]
  },

]

export default new Router({
  mode: 'hash',
  // mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRouterMap
})
