<template>
    <div>
        <el-dialog
          title="消息提醒"
          @open="messageWarnDialog"
          :visible.sync="dialogMessageVisible"
          width="1100px">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <div class="btns-box">
              <el-button
                v-if="unreadMessageWarnList.length && activeName=='0'"
                v-preventReClick
                :disabled="unreadDisabled"
                class="read-button"
                icon="el-icon-check"
                @click="readTable"
              >批量已读</el-button>
            </div>
            <el-tab-pane label="未读" name="0">
              <div class="message-list">
                <OrderMessageTable 
                  v-if="activeName=='0'"
                  ref="unreadtable"
                  :tableData="unreadMessageWarnList"
                  :cols="cols"
                  :orderMessageOperate="orderMessageOperate"
                  :lookMessageWarn="lookMessageWarn"
                  :theme="theme"
                />
              </div>
            </el-tab-pane>
            <el-tab-pane label="已读" name="1">
              <div class="message-list">
                <OrderMessageTable 
                  v-if="activeName=='1'"
                  ref="readtable"
                  :tableData="readMessageWarnList"
                  :cols="cols"
                  :orderMessageOperate="orderMessageOperate"
                  :lookMessageWarn="lookMessageWarn"
                  :theme="theme"
                />
              </div>
            </el-tab-pane>
            <el-tab-pane label="全部" name="2">
              <div class="message-list">
                <OrderMessageTable 
                  v-if="activeName=='2'"
                  ref="table"
                  :tableData="messageWarnList"
                  :cols="cols"
                  :orderMessageOperate="orderMessageOperate"
                  :lookMessageWarn="lookMessageWarn"
                  :theme="theme"
                />
              </div>
            </el-tab-pane>
          </el-tabs>
          <div :class="theme == 'white' ? 'page-box' :'blue-page-box'">
            <el-pagination
              hide-on-single-page
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-size="pageSize"
              :page-sizes="[2,10, 15, 20, 30]"
              layout="total,prev, pager, next, jumper"
              :total="total">
            </el-pagination>
          </div>
        </el-dialog>
    </div>
</template>

<script>
import MessageTable from "@/components/tableExcel/MessageTable.vue";
import { api_orderInfoList } from '@/apis/orders/order'
import { api_messageWarnView,api_messageWarnbatchBiew } from '@/apis/messageWarn'
import local from '@/utils/local';
    export default {
      components: {
        OrderMessageTable: MessageTable
      },
        props: {
          //全部消息
          messageWarnList: {
            type: Array,
            default() {
              return [];
            }
          },
          //未读消息
          unreadMessageWarnList: {
            type: Array,
            default() {
              return [];
            }
          },
          //已读消息
          readMessageWarnList: {
            type: Array,
            default() {
              return [];
            }
          },
        },
        data(){
           return{
            orderMessageOperate: true,
            unreadDisabled:false,
            theme:'white',
            activeName:'0',//默认未读模块
            historyActiveName:'0',//前一次默认未读模块
            dialogMessageVisible:false,//消息提醒,
            currentPage:1,
            pageSize:10,
            total:10,
            size:10,
            cols: [
              {
                prop: "title",
                label: "标题",
                type: "input",
                width:'150'
              },
              {
                prop: "content",
                label: "内容",
                type: "input",
                width:'550'
              },
              {
                prop: "viewStatus",
                label: "状态",
                type: "input",
                width:'80'
              },
              {
                prop: "created",
                label: "时间",
                type: "input",
                width:'130'
              },
            ]
           }
        },
        created(){
          this.theme = local.get("theme") ? local.get("theme") : 'white';
          this.$nextTick(() => {
            console.log(this.messageWarnList,'messageWarnList===========');
            console.log(this.unreadMessageWarnList);
            console.log(this.readMessageWarnList);
          })           
        },
        methods:{
          messageWarnDialog(){
            this.theme = local.get("theme");
          },
          readTable(){
            if(!this.$refs.unreadtable.multipleSelection.length){
              return this.$message.error('请先勾选需要处理的数据！')
            }
            this.$confirm(
              `确认批量已读选中的${this.$refs.unreadtable.multipleSelection.length}条数据?`,
              "提示",
              {
                confirmButtonText: "全部已读",
                cancelButtonText: "取消",
                type: "warning"
              }
            )
              .then(async () => {
                let val = this.$refs.unreadtable.multipleSelection;
                let ids = [];
                if (val.length > 0) {
                  val.forEach(item => {
                    ids.push(item.id);
                  });
                  let { code, msg } = await api_messageWarnbatchBiew({
                    ids: ids
                  });
                  if (code == 200) {
                    this.handleClick({name:'0'})
                    this.$message.success("操作成功");
                  } else {
                    this.$message.error(msg);
                  }
                }
              })
              .catch(() => {});
          },
          unreadhandleCheckAllChange(val) {
            this.checkedCities = val ? [] : [];
            this.isunreadIndeterminate = false;
          },
          unreadhandleCheckedCitiesChange(value) {
            let checkedCount = value.length;
            this.unreadCheckAll = checkedCount === this.cities.length;
            this.isunreadIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
          },
          handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.size = val;
            this.$emit("changePagesize", this.currentPage - 1, val,this.activeName);
          },
          handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.currentPage = val;
            this.$emit("changeCurrent", val - 1, this.size,this.activeName);
          },
          handleClick(tab, event) {
            if(this.historyActiveName != tab.name){
              console.log('切换了tab');
              this.currentPage = 0;
            }
            this.activeName = tab.name;
            this.historyActiveName = tab.name;
            this.$emit("changeCurrent", this.currentPage, this.size,this.activeName);
          },
          async lookMessageWarn(item){
            console.log(item,'查看详情');
            if(local.get('roleCodes').includes(item.routePath)){
              let view = await api_messageWarnView({id:item.id});
              if(view.code==200){
                if(item.routePath == 'order:order'){
                  let {data,code,msg} = await api_orderInfoList({id:item.bizId});
                  if(code == 200){
                    if(data){
                      this.dialogMessageVisible = false;
                      this.$router.push({
                        path: "/order/order",
                        query: { orderNo: data.orderNo }
                      });
                    }else{
                      this.$message.error('找不到对应订单详情');
                    }
                  }else{
                    this.$message.error(msg);
                  }
                }
              }else{
                this.$message.error(view.msg);
              }
            }else{
              this.$message.error('暂无查看权限');
            }
          },
        }
    }
</script>

<style lang="less" scoped>
.message-list{
  max-height: 390px;
  overflow-y: scroll;
  .message-title{
    display: flex;
    border-bottom: 1px solid #E4E7ED;
    p{
      text-align: left;
      line-height: 40px;
    }
  }
  .message-title-title{
    width: 200px;
  }
  .message-title-content{
    flex: 1;
  }
  .message-title-viewStatus{
    width: 45px;
  }
  .message-title-created{
    width: 130px;
  }
  .operating-btn{
    margin-right: 8px;
  }
  .message-list-item{
    display: flex;
    padding-right: 10px;
    margin-bottom: 12px;
    border-bottom: 1px dashed #e1e1e1;
    p{
      text-align: left;
      line-height: 40px;
    }
  }
}
.page-box{
  margin-top: 8px;
  margin-right: 22px;
}
.btns-box{
  text-align: right;
  margin-right: 25px;
  margin-bottom:8px ;
  .read-button{
    color: #fff;
    background-color: #3674cd;
    opacity: 0.8;
    border: none;
  }
}

// 滚动条的宽度
/deep/ .message-list::-webkit-scrollbar {
      width: 8px; // 横向滚动条
      height: 8px; // 纵向滚动条 必写
    }
// 滚动条的滑块
/deep/ .message-list::-webkit-scrollbar-thumb {
  background-color: #a0abd2;
  border-radius: 3px;
}

.el-pagination{
  text-align: right;
}
.blue-page-box{
  margin-top: 8px;
  margin-right: 22px;
  /deep/.el-pagination__total{
    color:#fff;
  }
  /deep/.el-pagination__jump{
    color:#fff;
  }
  /deep/.el-pagination{
    button{
      background-color: transparent;
    }
  }
  /deep/.el-pager li{
    background: transparent;
    color: #fff;
    &.active{
      color:#409EFF
    }
  }
}
</style>