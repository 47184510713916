/*
    封装axios发送get/post 请求
*/

// 引入axios和qs
import axios from 'axios';
import qs from 'qs';
// 引入本地存储工具js
import local from '@/utils/local';
//引入弹框信息
import { Message } from 'element-ui';
import router from '@/router';
import store from '@/store';

//设置默认请求地址
axios.defaults.baseURL = 'https://gllog.com.cn';//线上接口(新) //login ==》handleLogin使用线上的重定向地址
// axios.defaults.baseURL = 'http://10.226.128.34:9921';

// 
// 请求拦截器  发请求的时候，可以做一些事情，携带一些参数
axios.interceptors.request.use(config => {
    var token = local.get("token");
    if (token) {
        // 把token携带到请求拦截器中
        config.headers.Authorization = token;
        config.headers.Clientid = 1;
    }
    return config;
});

// 响应拦截器  请求拿到数据过后，统一处理一些结果
axios.interceptors.response.use((response) => {
    console.log(response);
    // 判断code和msg
    if (response.data.code != undefined && response.data.msg != undefined) {
        let { code, msg } = response.data;
        if (code === 200) {
            Message({
                type: 'success',
                message: msg,
            });
        } else if (code == 4100) {
            local.clear();
            router.push('/')
        } else {

        }
    }
    return response;
}, (error) => {
    // 4100 无效token
    if (error.response.data.status == 404) {
        Message({
            type: 'error',
            message: '地址接口丢失',
        });
    } else if(error.response.data.status == 500){
        Message({
            type: 'error',
            message: '内部服务器错误',
        });
    }else {
        Message({
            type: 'error',
            message: error.response.data.error,
        });
    }
    local.clear();
    router.push('/');
});
// 封装get、post请求
export default {
    get(url, params) {
        return new Promise((resolve, reject) => {
            axios.get(url, { params })
                .then(res => { resolve(res.data) }, err => { reject(err) })
                .catch((error) => console.log(error))
        })
    },
    post(url, params) {
        return new Promise((resolve, reject) => {
            axios.post(url, JSON.stringify(params), {
                headers: {
                    'Access-Control-Allow-Origin': '*/*',
                    'Content-Type': 'application/json',
                    // 'User-Agent':'PostmanRuntime/7.26.8',
                    // 'Accept-Encoding': 'gzip, deflate, br',
                    // 'Accept':'*/*',
                    // 'Connection':'keep-alive'
                }
            })
                .then(res => { resolve(res.data) }, err => { reject(err) })
                .catch((error) => console.log(error))
        })
    }
}




// export function post(url,params){
//     return axios.post(url, qs.stringify(params));
// }
// export function get(url,params){
//     return axios.get(url, {params});
// }
