<template>
  <div id="app" :style="`height:${screenHeight}px`">
    <router-view />
  </div>
</template>

<script>
import local from "@/utils/local";
export default {
  data() {
    return {
      screenWidth: document.body.clientWidth,
      screenHeight: window.innerHeight
    };
  },
  created() {
    console.log(
      "this.$refs.wrapper",
      document.body.screenWidth,
      document.body.screenHeight
    );
  },
  mounted() {
    window.onload = function() {
      if (!window.sessionStorage["login"]) {
        // 关闭浏览器时清空
        local.clear();
      }
    };
    window.onunload = function() {
      window.sessionStorage["login"] = true;
    };
    window.onbeforeunload = function() {
      window.sessionStorage["login"] = true;
    };
    // 监听屏幕宽度和高度变化
    window.onresize = () => {
      return (() => {
        this.$nextTick(() => {
          console.log(window.clientWidth,'window.clientWidth');
          console.log(document.body.clientHeight);
          if (document.body.clientWidth < 800) {
            this.screenWidth = 800;
          } else {
            this.screenWidth = document.body.clientWidth;
          }
          this.screenHeight = window.innerHeight;
          console.log("this.$refs.wrapper", this.screenWidth,this.screenHeight);
        });
      })();
    };
  },
  watch: {
    screenWidth: {
      immediate: true,
      handler: function(newVal) {
        if (!this.timer) {
          this.screenWidth = newVal;
          this.timer = true;
          let _this = this;
          setTimeout(() => {
            console.log(_this.screenWidth);
            this.$store.commit("screenWidthFun", _this.screenWidth);
            _this.timer = false;
          }, 400);
        }
        // if(newVal)
      }
    }
  }
};
</script>

<style lang="less" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
body,
html,
#app {
  width: 100%;
  height: 100%;
  // background: url("./assets/imgs/indexPng/beijing.png") no-repeat;
  // background: url(./assets/blueImgs/login/背景.png) no-repeat;

  &::-webkit-scrollbar {
    //隐藏滑动条
    display: none;
  }
}
.blue{
  background: url(./assets/blueImgs/login/背景.png) no-repeat;
  background-size: cover;
}
.white{
  background: url("./assets/imgs/indexPng/beijing.png") no-repeat;
  background-size: cover;
}
</style>
